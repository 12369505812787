<template>
  <div class="logManger">
    <div class="log-manager-title">
      <div class="text" :class="{ active: activeName == 'handleLog' }">
        <span>操作日志</span>
        <div class="line" v-if="activeName == 'handleLog'"></div>
      </div>
    </div>
      <!-- <el-tabs
          :value="activeName"
          class="taskTabs"
          type="card"
          @tab-click="handleClick">
          <el-tab-pane name="handleLog" label="操作日志"> -->
              <handle-log v-if="activeName == 'handleLog'" style="margin-top: 3.125vw"></handle-log>
          <!-- </el-tab-pane> -->
          <!-- <el-tab-pane name="loginLog" label="登录日志">
              <loginin-log v-if="activeName == 'loginLog'"></loginin-log>
          </el-tab-pane> -->
      <!-- </el-tabs> -->
  </div>
</template>

<script>
import HandleLog from './components/HandleLog.vue';
import LogininLog from './components/LogininLog.vue';
// import AbnormalLog from "./components/AbnormalLog.vue";
export default {
  components: {
    HandleLog,
    LogininLog,
    // AbnormalLog,
  },
  data() {
    return {
      activeName: 'handleLog',
      form: {
        type: '',
        options: [
          { label: '异常', value: '9' },
          { label: '正常', value: '0' }
        ]
      },
    };
  },
  methods: {
    /** tabs */
    handleClick(tab) {
      this.activeName = tab.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.logManger{
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  .log-manager-title {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.15);
    .text {
      height: 100%;
      margin-left: 27px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      position: relative;
      display: flex;
      align-items: center;
      &.active{
        font-size: 20px;
        font-weight: bold;
      }
      &:first-child{
        margin-left: 24px;
      }
      .line {
        width: 24px;
        height: 3px;
        background-color: #597ef7;
        position: absolute;
        bottom: 0;
        left: 40px;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
