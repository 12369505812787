<template>
  <div class="handleLog">
    <!-- 表单 -->
    <el-form :model="form" onsubmit="return false" :inline="true">
      <el-row>
        <el-col :span="4">
          <el-form-item class="search-form-item">
            <el-input
              style="width: 100%"
              v-model="form.keyword"
              clearable
              placeholder="输入搜索关键字"
            />
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="search-form-item">
            <el-select
              style="width: 100%"
              v-model="form.module"
              clearable
              placeholder="请选择所属模块"
            >
              <el-option
                v-for="item in moduleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="search-form-item">
            <el-select style="width: 100%" v-model="form.type" clearable placeholder="请选择操作类型">
              <el-option
                v-for="item in handleOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="search-form-item">
            <el-date-picker
              style="width: 100%"
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="操作开始时间"
              end-placeholder="操作结束时间"
              format="yyyy-MM-dd HH:mm:00"
              value-format="yyyy-MM-dd HH:mm:00"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item class="search-form-item">
            <el-button type="primary" icon="el-icon-search" @click="searchFun"
              >查询</el-button
            >
            <el-button @click="resetFun">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      style="width: 100%"
      stripe
      border
    ><!--v-loading="tableLoading"-->
      <el-table-column prop="id" label="序号" align="center"></el-table-column>
      <el-table-column prop="clientType" label="终端类型" align="center">
        <template slot-scope="scope">
          {{
            scope.row.clientType == "1"
              ? "web端"
              : scope.row.clientType == "2"
              ? "移动端"
              : "其他"
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="clientName"
        label="终端名称"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.clientName }}</div>
            <div class="handleData">{{ scope.row.clientName }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="os"
        label="操作系统"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.os }}</div>
            <div class="handleData">{{ scope.row.os }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="mac" label="mac地址" align="center"></el-table-column> -->
      <el-table-column
        prop="ipAddr"
        label="ip地址"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.ipAddr }}</div>
            <div class="handleData">{{ scope.row.ipAddr }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="operateType" label="操作类型" min-width="80" align="center">
        <template slot-scope="scope">
          {{
            scope.row.operateType == "1"
              ? "添加"
              : scope.row.operateType == "2"
              ? "编辑"
              : scope.row.operateType == "3"
              ? "删除"
              : scope.row.operateType == "4"
              ? "查询"
              : ""
          }}
        </template>
      </el-table-column>
      <el-table-column
        prop="module"
        label="功能模块"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.module }}</div>
            <div class="handleData">{{ scope.row.module }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column
        prop="requestUri"
        label="请求URI"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.requestUri }}</div>
            <div class="handleData">{{ scope.row.requestUri }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="method" label="操作方式get/post等" align="center"></el-table-column> -->
      <el-table-column
        prop="params"
        label="操作数据"
        align="center"
      >
        <template slot-scope="scope">
          <el-tooltip placement="top" popper-class="customPopper" effect="light">
            <div class="allData" slot="content">{{ scope.row.params }}</div>
            <div class="handleData">{{ scope.row.params }}</div>
          </el-tooltip>
        </template>
      </el-table-column>
      <!-- <el-table-column prop="userAgent" label="用户代理" align="center"></el-table-column> -->
      <el-table-column
        prop="create"
        label="操作人"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        align="center"
        width="150"
      ></el-table-column>
      <!-- <el-table-column label="操作" align="center" width="10.41667vw">
                <template slot-scope="scope">
                    <el-button size="small" @click="handleCheck(scope.row)">查看</el-button>
                    <el-button size="small" @click="handleDel(scope.row)" v-if="permissions.includes('sys_log_del')">删除</el-button>
                </template>
            </el-table-column> -->
    </el-table>
    <!-- 分页 -->
    <div class="course-wrapper-footer ">
      <div class="footer-left">
        显示第{{(pagination.currentPage - 1) * pagination.Size + 1}}-{{(pagination.currentPage - 1) * pagination.Size + tableData.length}}条记录，共{{pagination.total}}条记录
      </div>
      <div class="footer-right">
        <el-pagination
          background
          @size-change="handleSizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
          :page-size="pagination.Size"
          style="text-align: right; margin-top: 0.52083vw"
          @current-change="handleCurrentChangePage"
          :current-page.sync="pagination.currentPage"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 对话框 -->
    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      width="38%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <el-form :model="dialogForm" label-width="5.20833vw" :inline="true">
        <el-form-item label="类型">
          <el-select v-model="dialogForm.type">
            <el-option
              v-for="item in form.options"
              :key="item.brandId"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input v-model="dialogForm.title" readonly clearable />
        </el-form-item>
        <el-form-item label="IP地址">
          <el-input v-model="dialogForm.remoteAddr" readonly clearable />
        </el-form-item>
        <el-form-item label="请求方式">
          <el-input v-model="dialogForm.method" readonly clearable />
        </el-form-item>
        <el-form-item label="客户端">
          <el-input v-model.number="dialogForm.serviceId" readonly clearable />
        </el-form-item>
        <el-form-item label="请求时间">
          <el-input v-model="dialogForm.time" readonly clearable />
        </el-form-item>
        <el-form-item label="创建时间">
          <el-input v-model="dialogForm.createTime" readonly clearable />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {
        keyword: '',
        type: '',
        module: '',
      },
      dateTime: [],
      handleOptions: [
        { label: '添加', value: '1' },
        { label: '编辑', value: '2' },
        { label: '删除', value: '3' },
        { label: '查询', value: '4' },
      ], // 操作类型下拉
      moduleOptions: [], // 所属模块下拉
      dialogVisible: false,
      tableData: [],
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        Size: 10, // 每页显示多少条
      },
      dialogForm: {},
      tableLoading: false, // 表格loading
      pageLoading: null,
    };
  },
  created() {
    // this.tableLoading = true;
    this.pageLoading = this.$loading({
      lock: true,
      fullscreen: true,
      text: '加载中',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: "loadingStyle"
    });
    this.getModuleOptions();
    this.init();
  },
  computed: mapGetters(['permissions']),
  methods: {
    /** 初始化 */
    init() {
      let params = {
        keyword: this.form.keyword ? this.form.keyword : undefined,
        type: this.form.type ? this.form.type : undefined,
        module: this.form.module ? this.form.module : undefined,
        startTime: this.dateTime.length > 0 ? this.dateTime[0] : undefined,
        endTime: this.dateTime.length > 0 ? this.dateTime[1] : undefined,
        current: this.pagination.currentPage,
        size: this.pagination.Size,
      };
      this.$api
        .fetchHandleLogList(params)
        .then((res) => {
          this.pageLoading.close()
          // this.tableLoading = false;
          if (res.data.code == '0') {
            this.tableData = res.data.data.records;
            this.pagination.total = res.data.data.total;
          } else {
            this.$message.error(res.data.msg);
          }
        }).catch(() => {
          this.pageLoading.close()
        });
    },
    // 获取所属模块option
    getModuleOptions() {
      this.$api
        .getLogModule({})
        .then((res) => {
          if (res.data.code == '0') {
            let options = [];
            res.data.data.map((item) => {
              let info = {
                label: item,
                value: item
              };
              options.push(info);
            });
            this.moduleOptions = options;
          } else {
            this.$message.error(res.data.msg);
          }
        });
    },
    // 查询
    searchFun() {
      this.pagination.currentPage = 1;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.init();
    },
    // 重置
    resetFun() {
      this.form =  {
        keyword: '',
        type: '',
        module: '',
      };
      this.dateTime = [];
      this.pagination.currentPage = 1;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.init();
    },
    /** 行内-查看 */
    handleCheck(row) {
      this.dialogForm = {
        type: row.type,
        title: row.title,
        remoteAddr: row.remoteAddr,
        method: row.method,
        serviceId: row.serviceId,
        time: row.time,
        createTime: row.createTime,
      };
      this.dialogVisible = true;
    },
    /** 行内-删除 */
    handleDel(row) {
      this.$confirm(`是否确认删除id为${ row.id }的日志?`, {
        type: 'warning',
        closeOnClickModal: false,
      }).then(() => {
        this.$api.delLog(row.id).then((res) => {
          if (res.data.code == '0') {
            this.$message.success('删除成功');
            // this.tableLoading = true;
            this.pageLoading = this.$loading({
              lock: true,
              fullscreen: true,
              text: '加载中',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)',
              customClass: "loadingStyle"
            });
            this.init();
          }
        });
      });
    },
    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.init();
    },
    handleSizeChange(val) {
      this.pagination.Size = val;
      // this.tableLoading = true;
      this.pageLoading = this.$loading({
        lock: true,
        fullscreen: true,
        text: '加载中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        customClass: "loadingStyle"
      });
      this.init();
    },
    /** 对话框关闭事件 */
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss">
.course-wrapper-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .footer-left {
    color: #7c7c7c;
    font-size: 14px;
  }
}
.allData{
  max-width: 300px;
  white-space: normal;
}
.handleData{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-form-item{
  width: calc(100% - 20px);
  .el-form-item__content{
    width: 100%;
  }
}
</style>
