<template>
    <div class="loginLog">
        <!-- 表格 -->
        <el-table :data="tableData" style="width: 100%"
                  stripe border
                  height="5.20833vw"
                  v-table-auto-height>
            <el-table-column prop="id" label="序号" align="center"></el-table-column>
            <!-- <el-table-column prop="type" label="类型" align="center">
                <template slot-scope="scope">
                    {{ scope.row.type == '0'? '正常': '异常' }}
                </template>
            </el-table-column> -->
            <el-table-column prop="userId" label="员工id" align="center"></el-table-column>
            <el-table-column prop="userName" label="用户名" align="center"></el-table-column>
            <el-table-column prop="userPhone" label="手机号" align="center"></el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" align="center" width="180"></el-table-column>
            <!-- <el-table-column label="操作" align="center" width="10.41667vw">
                <template slot-scope="scope">
                    <el-button size="small" @click="handleCheck(scope.row)">查看</el-button>
                    <el-button size="small" @click="handleDel(scope.row)" v-if="permissions.includes('sys_log_del')">删除</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <!-- 分页 -->
        <el-pagination
            background
            @size-change="handleSizeChange"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pagination.total"
            :page-size="pagination.Size"
            style="text-align: right; margin-top: 0.52083vw"
            @current-change="handleCurrentChangePage"
            :current-page.sync="pagination.currentPage">
        </el-pagination>
        <!-- 对话框 -->
        <el-dialog
            title="查看"
            :visible.sync="dialogVisible"
            width="38%"
            :close-on-click-modal="false"
            :before-close="handleClose">
            <el-form
                :model="dialogForm"
                label-width="5.20833vw"
                :inline="true">
                <el-form-item label="类型">
                    <el-select v-model="dialogForm.type" disabled>
                        <el-option v-for="item in form.options" :key="item.brandId" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="标题">
                    <el-input v-model="dialogForm.title" readonly clearable />
                </el-form-item>
                <el-form-item label="IP地址">
                    <el-input v-model="dialogForm.remoteAddr" readonly clearable />
                </el-form-item>
                <el-form-item label="请求方式">
                    <el-input v-model="dialogForm.method" readonly clearable />
                </el-form-item>
                <el-form-item label="客户端">
                    <el-input v-model.number="dialogForm.serviceId" readonly clearable />
                </el-form-item>
                <el-form-item label="请求时间">
                    <el-input v-model="dialogForm.time" readonly clearable />
                </el-form-item>
                <el-form-item label="创建时间">
                    <el-input v-model="dialogForm.createTime" readonly clearable />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取消</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
import tool from '@/utils/tool';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      form: {
        type: '',
        options: [
          { label: '异常', value: '9' },
          { label: '正常', value: '0' }
        ]
      },
      dialogVisible: false,
      tableData: [],
      pagination: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        Size: 10 // 每页显示多少条
      },
      dialogForm: {},
    };
  },
  computed:mapGetters(['permissions']),
  created() {
    this.init();
  },
  methods: {
    /** 初始化 */
    init() {
      this.$api.fetchLoginLogList(Object.assign({
        // descs: 'create_time',
        // type: this.form.type || 0,
        // title: "登录",
        current: this.pagination.currentPage,
        size: this.pagination.Size
      })).then(res => {
        if (res.data.code == '0') {
          let loginData = JSON.parse(res.data.data).records;
          loginData.map((item) => {
            item.createTime = tool.formatTime(item.createTime);
          });
          this.tableData = loginData;
          this.pagination.total = JSON.parse(res.data.data).total;
        }
      });
    },
    /** 行内-查看 */
    handleCheck(row) {
      this.dialogForm = {
        type: row.type,
        title: row.title,
        remoteAddr: row.remoteAddr,
        method: row.method,
        serviceId: row.serviceId,
        time: row.time,
        createTime: row.createTime
      };
      this.dialogVisible = true;
    },
    /** 行内-删除 */
    handleDel(row) {
      this.$confirm(`是否确认删除id为${ row.id }的日志?`, {
        type: 'warning',
        closeOnClickModal: false
      }).then(() => {
        this.$api.delLog(row.id).then(res => {
          if (res.data.code == '0') {
            this.$message.success('删除成功');
            this.init();
          }
        });
      });
    },
    /** 分页 */
    handleCurrentChangePage(val) {
      this.pagination.currentPage = val;
      this.init();
    },
    handleSizeChange(val) {
      this.pagination.Size = val;
      this.init();
    },
    /** 对话框关闭事件 */
    handleClose() {
      this.dialogVisible = false;
    },
  },

};
</script>
